import React, { Component, useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import './datepicker.scss'

const Datepicker = () => {
    const [startDate, setStartDate] = useState(new Date());
    return (
      <DatePicker 
       filterDate = {(date) => {
        return moment() <= date;
       }}
       dateFormat="MM/dd/yy"
       selected={startDate} 
       onChange={date => setStartDate(date)} />
    )
  }

  export default Datepicker