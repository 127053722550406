import React, { Fragment } from 'react'

export default () => {


    function ContactInfo() {

        const dropDownValue = (e) => {
            document.getElementById('HomeStatus').value = e.target.value
        }

        return (
            <div className="input__container">
                <h2 style={{color:"black"}}>FINANCE APPLICATION</h2>
                <div className="input__title">
                    <h2>CONTACT INFO</h2>
                </div>

                <label>First Name*</label>
                <input type="text" className="input__field" name="FirstName" required />

                <label>Middle Initial</label>
                <input type="text" className="input__field" name="MiddleInitial" />

                <label>Last Name*</label>
                <input type="text" className="input__field" name="LastName" required />

                <label>Date of Birth*</label>
                <input type="date" className="input__field" name="DOB" required />

                <label>Social Security Number*</label>
                <input  type="text" className="input__field" name="SSN" required />

                <label>Driver's License #*</label>
                <input  type="text" className="input__field" name="DLN" required />

                <label>Driver's License State*</label>
                <input  type="text" className="input__field" name="DLS" required />

                <label>Email Address*</label>
                <input type="email" className="input__field" name="Email" required />

                <label>Phone #*</label>
                <input type="text" className="input__field" name="Phone" required />

                <label>Street Address*</label>
                <input type="text" className="input__field" name="Address" required />

                <label>Address Line 2</label>
                <input type="text" className="input__field" name="Address2" />

                <label>City*</label>
                <input  type="text" className="input__field" name="City" required />

                <label>State*</label>
                <input  type="text" className="input__field" name="State" required />

                <label>Zip Code*</label>
                <input type="number" className="input__field" name="ZipCode" required />

                <label>Rent or Own?*</label>
                <select id="HomeStatus" name="HomeStatus" className="input__field" required onChange={dropDownValue}>
                    <option disabled selected hidden>Select Status</option>
                    <option value="Rent">Rent</option>
                    <option value="Own">Own</option>
                </select>

                <label>Date of Residency*</label>
                <input type="date" className="input__field" name="Residency" required />

                <label>Monthly Rent/Mortgage*</label>
                <input type="text" className="input__field" name="MortgageRent" required />
            </div>
        )
    }

    function EmploymentInfo() {
        return (
            <div className="input__container">
                <div className="input__title">
                    <h2>EMPLOYMENT INFO</h2>
                </div>

                <label>Employer Name</label>
                <input type="text" className="input__field" name="EmployerName" />

                <label>Employer Phone #</label>
                <input  type="text" className="input__field" name="EmployerPhone" />

                <label>Job Title*</label>
                <input  type="text" className="input__field" name="JobTitle" required />

                <label>Date of Employment</label>
                <input  type="date" className="input__field" name="DOE" />

                <label>Gross Monthly Income*</label>
                <input type="text" className="input__field" name="MonthlyIncome" required />

                <label>Additional Income Source</label>
                <input type="text" className="input__field" name="AdditionalIncomeSource" />

                <label>Additional Income Amount</label>
                <input type="text" className="input__field" name="AdditionalIncomeAmount" />
            </div>
        )
    }

    function VehicleInfo() {

        const dropDownValue = (e) => {
            document.getElementById('PurchaseType').value = e.target.value
        }

        return (
            <div className="input__container">
                <div className="input__title">
                    <h2>TELL US ABOUT THE VEHICLE YOU WANT</h2>
                </div>

                <label>Purchase Type*</label>
                <select id="PurchaseType" name="PurchaseType" className="input__field" onChange={dropDownValue} required >
                    <option disabled selected hidden>Select Purchase Type</option>
                    <option value="New Purchase">New Purchase</option>
                    <option value="New Lease">New Lease</option>
                    <option value="Pre-Owned Purchase">Pre-Owned Purchase</option>
                </select>

                <label>Year</label>
                <input type="text" className="input__field" name="Year" />

                <label>Make</label>
                <input type="text" className="input__field" name="Make" />

                <label>Model</label>
                <input type="text" className="input__field" name="Model" />

                <label>Trim</label>
                <input type="text" className="input__field" name="Trim" />

                <label>Total Loan Amount*</label>
                <input  type="text" className="input__field" name="LoanAmount" required />
            </div>
        )
    }

    function TradeInInfo() {
        return (
            <div className="input__container">
                <label>Trade-In?*</label>
                <div className="input__field radio" >
                    <label><input type="radio" name="TradeIn" value="Yes" required /> Yes</label>
                    <label><input type="radio" name="TradeIn" value="No" required /> No</label>
                </div>

                <label>Trade-In Year</label>
                <input  type="text" className="input__field" name="TradeInYear" />

                <label>Trade-In Make</label>
                <input  type="text" className="input__field" name="TradeInMake" />

                <label>Trade-In Model</label>
                <input  type="text" className="input__field" name="TradeInModel" />

                <label>Trade-In Mileage</label>
                <input  type="text" className="input__field" name="TradeInMileage" />
            </div>
        )
    }

    return (
        <Fragment>
            <ContactInfo/>
            <EmploymentInfo/>
            <VehicleInfo/>
            <TradeInInfo/>
        </Fragment>
    )
}
